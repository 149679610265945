import React from "react";
import Banner from "./Layout/Banner";
import Card from "./Layout/Cards.jsx";
import SolbitWorks from "./Layout/SolbitWorks.jsx";
import Advantages from "./Layout/Advantages.jsx";
import Features from "./Layout/Features.jsx";
import Community from "./Layout/Community.jsx";

const Home = () => {
  return (
    <>
      <Banner />
      <Card />
      <SolbitWorks />
      <Advantages />
      <Features />
      <Community />
    </>
  );
};

export default Home;
