import React from "react";
import Communitydata from "../Jsondata/community.json";
import Card from "react-bootstrap/Card";
import AnimationTitle from "../../Animation/AnimationTitle";

const Community = () => {
  const handleClick = (link) => {
    window.open(link, "_blank");
  };
  return (
    <>
      <div className="community-container container">
        <AnimationTitle title="Community" />
        <div className="community-card-section row ">
          {Communitydata.map((data, index) => {
            return (
              <div
                className="col-lg-3 col-md-4 col-sm-6 col-6 "
                key={index}
                onClick={() => handleClick(data?.navigateTo)}>
                <Card className="community-card">
                  <Card.Body>
                    <div className="community-card-title-section">
                      <Card.Title className="community-card-title">
                        {data.title}
                      </Card.Title>
                    </div>
                    <div>
                      <Card.Img
                        variant="top"
                        src={data.img}
                        className="community-card-img"
                      />
                    </div>
                  </Card.Body>
                </Card>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Community;
