import React, { useState } from "react";
import "../Css/index.css";
import MessageModal from "../../Modal/Message";

const Banner = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="banner-section container">
        <div className="banner-left-side-content col-lg-6 col-12">
          <p className="banner-text">
            <span className="banner-span">Solana&apos;s </span>
            Speed, <br /> Bitcoin&apos;s Strength
          </p>
          <button onClick={() => setOpen(true)}>Launch App</button>
        </div>
        <div className="banner-img col-lg-6 col-12">
          <img
            src={"assets//Banner/banner-bg.png"}
            alt="banner-bg"
            className="banner-bg"
          />
          <img
            src={"assets/Banner/banner-bg-2.png"}
            alt="banner-bg"
            className="banner-bg-2"
          />
        </div>
      </div>
      {open && <MessageModal setOpen={setOpen} />}
    </>
  );
};

export default Banner;
