import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Component/Home/Home.jsx";
import Comingsoon from "./Pages/Comingsoon.jsx";
import Mainlayout from "./Component/Layouts/Mainlayout.jsx";
import PageNotFound from "./Pages/PageNotFound";
import Airdrop from "./Component/Airdrop/Airdrop.jsx";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "./App.css";
import analytics from "./Component/Modal/analytics";

function App() {
  analytics.init();

  return (
    <>
      <Routes>
        <Route element={<Mainlayout />}>
          <Route path="/" exact element={<Home />}></Route>
          <Route path="/airdrop" exact element={<Airdrop />}></Route>
        </Route>

        <Route path="/comingsoon" element={<Comingsoon />}></Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
