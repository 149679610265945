import React from "react";
import advantagesData from "../Jsondata/advantages.json";
import AnimationTitle from "../../Animation/AnimationTitle";

const Advantages = () => {
  return (
    <>
      <div className="advantages-container container">
        <AnimationTitle title="Our Advantages" />
        <div className="advantages-sub-section row">
          <div className="col-lg-6 col-sm-10 col-12 advantages-sub-section-left-side-content">
            <img src="assets/Advantages/Advantages.png" alt="" />
          </div>
          <div className=" col-lg-6 col-sm-10 col-12 advantages-sub-section-right-side-content">
            {advantagesData.map(({ title, description }, i) => {
              return (
                <div
                  key={i}
                  className="advantages-sub-section-right-side-content-item">
                  <h3>{title}</h3>
                  <p className="section-description">{description}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Advantages;
