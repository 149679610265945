import React from "react";
import "./Css/index.css";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";

const Footer = () => {
  return (
    <>
      <div className="footer-container container">
        <div className="footer-leftside-section">
          <img
            src={"assets/Header/logo.svg"}
            alt="footer"
            className="footer-logo"
          />
          <div className="footer-social-icon support-navlink">
            <NavLink to="https://twitter.com/solbitnetwork" target="_blank">
              <img src={"assets/Footer/tweeter-1.svg"} alt="twitter" />
            </NavLink>
            <NavLink to="https://t.me/solbitLayer2" target="_blank">
              <img src={"assets/Footer/footer-telegram.svg"} alt="telegram" />
            </NavLink>
            <NavLink>
              <img src={"assets/Footer/footer-media.svg"} alt="media" />
            </NavLink>
          </div>
        </div>
        <div className="footer-rightside-section">
          <div className="footer-rightside-section-1">
            <h3>Function</h3>
            <div className="support-navlink">
              {/* <NavLink>
                <p>SPL Token</p>
              </NavLink> */}
              <Link
                to="SPL Token"
                smooth={true}
                duration={100}
                style={{ color: "white", cursor: "pointer" }}>
                SPL Token
              </Link>
              <NavLink>
                <p>BRC-20</p>
              </NavLink>
              <NavLink>
                <p>Transform</p>
              </NavLink>
              <NavLink>
                <p>Layer-2</p>
              </NavLink>
            </div>
          </div>

          <div className="footer-rightside-section-2">
            <h3>Developers</h3>
            <div className="support-navlink">
              <NavLink>
                <p>Developers</p>
              </NavLink>
              <NavLink>
                <p>Github</p>
              </NavLink>
              <NavLink>
                <p>Blog</p>
              </NavLink>
            </div>
          </div>

          <div className="footer-rightside-section-3">
            <h3>Support</h3>
            <div className="support-navlink">
              <NavLink to="https://t.me/solbitLayer2" target="_blank">
                Telegram
              </NavLink>
              <NavLink to="https://twitter.com/solbitnetwork" target="_blank">
                Twitter
              </NavLink>
              <NavLink>Contact</NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
