import React, { useState, useEffect } from "react";
import "./Css/index.css";
import { NavLink, useLocation } from "react-router-dom";
import MessageModal from "../Modal/Message";
import { Link } from "react-scroll";

const Header = () => {
  const [open, setOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("");

  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };

  useEffect(() => {
    console.log("hello");
    if (window.location.pathname === "/") {
      setActiveLink("");
    }
  }, [window.location.pathname]);

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  return (
    <>
      <div className="top-section">
        <div className="header-section container">
          <div className="header-left-side-content">
            <img
              src={"assets/Header/logo.svg"}
              alt="logo"
              className="bitcoin-logo"
            />
          </div>
          <div
            className="burger-menu d-block d-md-none"
            data-bs-toggle="offcanvas"
            href="#offcanvasExample"
            role="button"
            aria-controls="offcanvasExample">
            <img src={"assets/Header/burger-menu.png"} alt="" />
          </div>
          <div className="header-right-side-content d-none d-md-flex">
            <nav>
              {/* <NavLink to="/#conatct">SPL Token</NavLink> */}
              <NavLink
                to="/airdrop"
                className={`${
                  activeLink === "SPL Token"
                    ? "header-active-link"
                    : "not-active-link"
                }`}
                onClick={() => handleLinkClick("SPL Token")}>
                AIRDROP
              </NavLink>
              <Link
                to="SPL Token"
                smooth={true}
                duration={100}
                style={{ color: "white", cursor: "pointer" }}>
                SPL Token
              </Link>
              <NavLink to="/brc20" isActive={() => isActive("/brc20")}>
                BRC-20
              </NavLink>
              <NavLink to="/transform" isActive={() => isActive("/transform")}>
                Transform
              </NavLink>
              <NavLink to="/layer2" isActive={() => isActive("/layer2")}>
                Layer-2
              </NavLink>{" "}
            </nav>
            <button className="launch-btn" onClick={() => setOpen(true)}>
              Launch App
            </button>
          </div>
        </div>
      </div>
      <div
        className="offcanvas offcanvas-start"
        tabIndex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel">
        <div className="offcanvas-header">
          <div className="logo">
            <img
              src={"assets/Header/logo.svg"}
              alt="logo"
              className="bitcoin-logo"
            />
          </div>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <div className="sidebar-menu">
            <nav>
              <Link
                to="SPL Token"
                smooth={true}
                duration={100}
                style={{ color: "white", cursor: "pointer" }}
                aria-label="Close"
                data-bs-dismiss="offcanvas">
                SPL Token
              </Link>
              <NavLink>BRC-20</NavLink>
              <NavLink>Transform</NavLink>
              <NavLink>Layer-2</NavLink>
            </nav>
            <button
              className="launch-btn"
              onClick={() => setOpen(true)}
              aria-label="Close"
              data-bs-dismiss="offcanvas">
              Launch App
            </button>
          </div>
        </div>
      </div>
      {open && <MessageModal setOpen={setOpen} />}
    </>
  );
};

export default Header;
