import React from "react";
import Card from "react-bootstrap/Card";
import featuresData from "../Jsondata/features.json";
import AnimationTitle from "../../Animation/AnimationTitle";
import "../../Animation/css/animation.css";

const Features = () => {
  return (
    <div className="features-container container">
      <AnimationTitle title="Features" />
      <div className="features-sub-section row">
        {featuresData.map(({ title, description, img }, i) => {
          return (
            <div className="col-md-6 col-lg-4 col-sm-10 col-12" key={i}>
              <Card className="card-section feauture-card-section ">
                <Card.Body style={{ display: "flex", flexDirection: "column" }}>
                  <Card.Title className="features-card-title">
                    <Card.Img variant="top" src={img} className="card-img" />
                    {title}
                  </Card.Title>
                  <Card.Text className="section-description ">
                    {description}
                  </Card.Text>
                  <button className="features-card-btn">Coming Soon</button>
                </Card.Body>
              </Card>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Features;
