import reactga4 from 'react-ga4';

const MEASUREMENT_ID = 'G-X1J7Z0ZW93';

function init() {
    // Enable debug mode on the local development environment
    reactga4.initialize(MEASUREMENT_ID)
}


function sendEvent(payload) {
    reactga4.event(payload)
}


export default {
    init,
    sendEvent
}
