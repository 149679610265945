import React from "react";
import Carddata from "../Jsondata/card.json";
import Card from "react-bootstrap/Card";
import { NavLink } from "react-router-dom";
import "../Css/index.css";
import analytics from "../../Modal/analytics";

const Cards = () => {
  const sendEventGA = () => {
    analytics.sendEvent({
      category: "官网按钮",
      action: "点击购买SOLBIT按钮",
      label: "购买SOLANA链上的$SOLBIT",
    });
  };

  return (
    <>
      <div className="card-container container">
        <div className="first-card col-md-6 col-sm-10 col-12">
          <Card className="card-section card-animation-section">
            <Card.Img
              variant="top"
              src={"assets/Card/coins.png"}
              className="card-img"
            />
            <Card.Body>
              <div className="card-body-inner">
                <Card.Title className="card-title">
                  $SOLBIT on our chain
                </Card.Title>
                <Card.Text className="card-description ">
                  $SOLBIT is the Bitcoin layer-2 Mainnet Coin. All the
                  ecosystems including BRC-20, Ordinals on Bitcoin Chain and
                  Tokens and community are able to intersect on Solbit Chain,
                  therefore adding value to $SOLBIT
                </Card.Text>
              </div>
              <div className="card-botoom-content">
                <button>Coming Soon</button>
                <img
                  src={"assets/Card/arrow.svg"}
                  alt="plus"
                  className="card-arrow"
                />
              </div>

              <div className="go-corner" href="#">
                <div className="go-arrow">→</div>
              </div>
            </Card.Body>
          </Card>
        </div>

        <div className="cards-content cards-content-inner row">
          {Carddata.map((data, index) => {
            return (
              <div
                className="col-md-6 col-sm-10 col-12"
                key={index}
                id={data?.id}>
                <Card className="card-section card-animation-section">
                  <Card.Img variant="top" src={data.img} className="card-img" />
                  <Card.Body>
                    <div className="card-body-inner">
                      <Card.Title className="card-title">
                        {data.title}
                      </Card.Title>
                      <Card.Text className="card-description ">
                        {data.description}
                      </Card.Text>
                    </div>
                    <div className="card-botoom-content">
                      {data.btnName === "Buy $SOLBIT" ? (
                        <NavLink
                          to="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=ETCWsv8oWMVZPKCNpR1VpYwqGJMc1uF5X3xdz2f63JwA&inputAmount=1&outputAmount=7462630190063.7991&fixed=in "
                          target="_blank">
                          <button onClick={sendEventGA}>{data.btnName}</button>
                        </NavLink>
                      ) : (
                        <NavLink>
                          <button>{data.btnName}</button>
                        </NavLink>
                      )}

                      <img
                        src={"assets/Card/arrow.svg"}
                        className="card-arrow"
                        alt="plus"
                      />
                    </div>
                    <div className="go-corner" href="#">
                      <div className="go-arrow">→</div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Cards;
