import React from "react";
import "./Css/index.css";

const Airdrop = () => {
  const array = [
    {
      title: "Twitter",
      btnname: "Twitter",
      link: "https://twitter.com/solbitnetwork",
    },
    {
      title: "Discord",
      btnname: "Discord",
      link: "https://discord.gg/mQ9FzTegs9  ",
    },
    {
      title: "Telegram",
      btnname: "Telegram",
      link: "https://t.me/SolbitLayer2",
    },
  ];
  return (
    <>
      <div className="anidrop-section container align-items-center">
        <div className="anidrop-left-content">
          <h1>
            <span className="social-name">Social</span> Network
          </h1>
          {array.map((item, index) => {
            return (
              <div
                className={`hexagon-container ${
                  index !== item.length - 1 ? "left" : "right"
                } `}
                key={index}>
                <div className="hexagon-content">
                  <p className="hexagon-shape">
                    <p className="numbers">{index + 1}</p>
                  </p>
                  {/* <p className="hexagon-number">{index + 1}</p> */}
                  <p className="anidrop-title">{item.title}</p>
                </div>
                <div className="hexagon-btn-content">
                  <button
                    className="rectangle-btn"
                    onClick={() => window.open(item.link, "_blank")}>
                    {item.btnname}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        <div className="anidrop-video-section">
          <video width="840" height="570" autoPlay loop muted>
            <source
              src={
                "assets/Anidrop/CodePen Demo - Google Chrome 2024-01-26 09-32-30.mp4"
              }
              type="video/mp4"></source>
          </video>
        </div>
      </div>
      ;
    </>
  );
};

export default Airdrop;
