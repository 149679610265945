import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./css/animation.css";

const AnimationTitle = ({ title }) => {
  const [isInView, setIsInView] = useState(false);
  const titleRef = useRef();

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setIsInView(true);
      } else {
        setIsInView(false);
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5,
    });
    observer.observe(titleRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <div
      className={`section-heading your-component ${isInView ? "in-view" : ""}`}>
      <h1 ref={titleRef} className="component-title section-heading-text">
        {title}
        <div className="underline section-heading-underline"></div>
      </h1>
    </div>
  );
};

AnimationTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default AnimationTitle;
