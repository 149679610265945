import React from "react";
import AnimationTitle from "../../Animation/AnimationTitle";

const SolbitWorks = () => {
  return (
    <>
      <div className="solbit-works-container container">
        <AnimationTitle title="How Solbit works?" />
        <div className="solbit-works-img">
          <img src="assets/SolbitWorks/Solbit-works.png" alt="" />
        </div>
        <div className="section-description">
          Solbit is a Bitcoin layer-2 chain based on Solana technology. It
          operates as a secondary blockchain dedicated to enhancing the
          scalability and efficiency of Bitcoin transactions. Users can
          seamlessly engage with Solbit to experience faster and more
          cost-effective Bitcoin transactions within the Solana ecosystem.
        </div>
      </div>
    </>
  );
};

export default SolbitWorks;
