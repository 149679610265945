import React from "react";
import "../App.css";

const Comingsoon = () => {
  return (
    <>
      <div className="coming-soon-section"></div>

      <div className="comming-soon-text">
        <h4 data-heading="Coming Soon">COMING SOON</h4>
        <span>
          We&apos;re currently working on createing our new website.
        </span>{" "}
        <br />
        <span>We&apos;ll be launching soon.</span>
      </div>
    </>
  );
};

export default Comingsoon;
