import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";

const Message = ({ setOpen }) => {
  return (
    <>
      <div
        className="modal show"
        style={{ display: "flex", alignItems: "center" }}>
        <Modal.Dialog>
          <Modal.Header
            closeButton
            onClick={() => setOpen(false)}></Modal.Header>

          <Modal.Body>
            <p>We are developing Solbit Layer2 Network, Please stay tuned!</p>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => setOpen(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </div>
    </>
  );
};

Message.propTypes = {
  setOpen: PropTypes.func.isRequired,
};

export default Message;
